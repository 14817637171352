
























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { LabelEntityModel, LabelQueryModel, ImporExcelEntityModel } from '@common-src/entity-model/label-entity';
import LabelService from '@common-src/service/label';
import { ViewModeType } from '@common-src/model/enum';
import ImportExcelComponent from './import-excel-dialog.vue';
import GroupComponent from '@common-src/mixins/group-component';

@Component({
    components: {
        'import-excel-component': ImportExcelComponent
    }
})
export default class LabelListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    LabelEntityModel = LabelEntityModel;
    ImporExcelEntityModel = ImporExcelEntityModel;
    created() {
        this.initTable({
            service: LabelService,
            queryModel: new LabelQueryModel(),
            tableColumns: LabelEntityModel.getTableColumns()
        });
        this.getList();
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as LabelQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/label?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/label');
        }
    }

    importExcel():void{
        const imporExcelEntityModel = new ImporExcelEntityModel();
        (this.$refs.importExcelDialog as ImportExcelComponent).dialogOpen(imporExcelEntityModel, null, ViewModeType.NEW);
    }

    exportExcel():void{
        this.startFullScreenLoading('正在导出...');
        LabelService.exportExcel().then(res => {
            this.showMessageSuccess('导出标签库成功');
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}

